<template>
  <v-navigation-drawer id="app-drawer" v-model="isDrawer" app floating persistent mobile-breakpoint="991" width="260">
    <v-row class="fill-height" tag="v-list" no-gutters>
      <v-list-item class="logo" to="/">
        <v-list-item-avatar class="list-item-avatar" size="32" tile>
          <v-img :src="logo" />
        </v-list-item-avatar>

        <v-list-item-title class="title">{{ appName }}</v-list-item-title>
      </v-list-item>

      <v-divider class="mb-2" />

      <SidebarModuleNav
        v-for="(menu, index) in menus"
        :key="`${menu.Group}-${index}`"
        :items="menu.items"
        :name="menu.Group"
        :color="color"
      />

      <template v-if="!isProduction">
        <v-divider class="my-4" />

        <v-list-item color="white" class="dummy" to="/dummy">
          <v-list-item-action>
            <v-icon>mdi-code-tags</v-icon>
          </v-list-item-action>
          <v-list-item-title>dummy</v-list-item-title>
        </v-list-item>
      </template>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  components: {
    SidebarModuleNav: _ => import('@/components/Sidebar/Module/Nav'),
  },
  setup(props, { root: { $store } }) {
    const logo = window.envs.APP_FAVICON || '/favicon.ico'

    // store
    const appName = computed(_ => $store.state.app.appName)
    const isProduction = computed(_ => $store.state.app.isProductionMode)
    const drawer = computed(_ => $store.state.app.drawer)
    const color = computed(_ => $store.state.app.color)
    const menus = computed(_ => $store.getters['auth/menus'])
    const setDrawer = toast => $store.commit('app/SET_DRAWER', toast)

    const isDrawer = computed({
      get: _ => drawer.value,
      set: val => setDrawer(val),
    })

    return {
      appName,
      logo,
      isProduction,
      menus,
      color,
      isDrawer,
    }
  },
})
</script>
